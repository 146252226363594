.body-style {
  overflow: hidden;
}

.gpt-container {
  height: calc(100vh - 60px);
  // padding-top: 60px;
}
.pdf-box-container {
  overflow: overlay;
  height: calc(100vh - 200px);
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
}
.pdf-card-header {
  background-color: rgba(0, 0, 0, 0.1);
}
.file-container {
  width: 100%;
}

.pdf-container {
  height: calc(100vh - 350px);
  overflow: auto;
}

// .spinner-container {
//   position: absolute;
//   top: 0;
//   left: 0;
//   background: rgba($color: #000000, $alpha: 0.6);
//   bottom: 0;
//   right: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex: 1;
// }

.grid-right-pane {
  background-image: url("../../assets/images/mesh.png");
  background-size: 100% auto;
}

.lg-container {
  margin: 5px auto;
  // height: 100vh;
  max-width: 1360px;
  width: 100%;
}

.sm-container {
  margin: 10px;
  // height: 100vh;
}

// .scrollbar::-webkit-scrollbar {
//   display: none;
// }

.center-align {
  text-align: center;
  margin-bottom: '20px';
}

.body-style {
  overflow: hidden;
}

.gpt-container {
  height: calc(100vh - 60px);
  padding-top: 60px;
}
.box-container {
  overflow: overlay;
  height: calc(100vh - 250px);
}
.card-header {
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}
.file-container {
  width: 100%;
}

.pdf-container {
  height: calc(100vh - 350px);
  overflow: auto;
}

// .spinner-container {
//   position: absolute;
//   top: 0;
//   left: 0;
//   background: rgba($color: #000000, $alpha: 0.6);
//   bottom: 0;
//   right: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex: 1;
// }

.grid-item {
  // padding: 0 20px;
}

.summary-container {
  height: calc(80vh - 95px);
  overflow: overlay;
  // display: flex;
  flex-direction: column;
}
.circular-progress-container {
  width: 250px;
  align-self: center;
}

.inner-card-container {
  // background-color: white;
  // padding: 0px 20px 20px 30px;
  border-radius: 20px;
  margin-top: 30px;
}

.grid-right-pane {
  background-image: url("../../assets/images/mesh.png");
  background-size: 100% auto;
}

.new_container{
  display: flex;
  flex:1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 70px;
}

.text{
  display: flex;
  flex:1;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  padding-top: 20px;
  // margin-bottom: -10px;
}
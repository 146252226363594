.body-style {
  overflow: hidden;
}

.gpt-container {
  height: calc(100vh - 60px);
}

.box-container {
  overflow: scroll;
  height: calc(100vh - 250px);
}

// .spinner-container {
//   position: absolute;
//   top: 0;
//   left: 0;
//   background: rgba($color: #000000, $alpha: 0.6);
//   bottom: 0;
//   right: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex: 1;
//   z-index: 999;
// }

.pdf-container1 {
  height: calc(100vh - 182px);
  overflow: auto;
}

.pdf-container1::-webkit-scrollbar {
  display: none;
}

.grid-left-pane {
  border: 20px solid white;
}

.grid-right-pane {
  background-image: url("../../assets/images/mesh.png");
  background-size: 100% auto;
}
.hero {
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/hero.png");
  background-size: cover;
  background-position: center;
  .content {
    display: flex;
    // flex: 2;
    // background-color: red;
    flex-direction: column;
    position: absolute;
    right: 1%;
    justify-content: right;
    align-items: end;
    font-weight: 900;
    max-width: 500px;
  }
  .content2 {
    display: flex;
    flex: 1;
  }
}

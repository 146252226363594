.chatbot {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: hidden;
}

.content {
  // flex: 1;
  // display: flex;
  // padding: 20px;
  // overflow-y: hidden;
  // justify-content: center;
  // align-items: center;
}
.mask-box {
    border-radius: 20px;
    position: relative;
    background: #ffeccf;
    box-shadow: 1px 1px 2px rgb(231, 201, 161) inset;
  }
  .mask-box .mask {
    width: 180px;
    height: 40px;
    border-radius: 20px;
    background: #EE851E;
    position: absolute;
    box-shadow: 1px 0 2px #ffc773;
    transition: all 0.5s ease;
  }
  .mask-box .MuiButton-root {
    border-radius: 20px;
    width: 180px;
    height: 40px;
    font-weight: bold;
    transition: all 0.2s 0.1s ease;
  }
  .mask-box .MuiButton-root:hover {
    opacity: 0.8;
  }
  
.body-style {
  overflow: hidden;
}

.gpt-container {
  height: calc(100vh - 60px);
  // padding-top: 60px;
}
.pdf-box-container {
  overflow: overlay;
  height: calc(100vh - 200px);
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
}
.pdf-card-header {
  background-color: rgba(0, 0, 0, 0.1);
}
.file-container {
  width: 100%;
}

.pdf-container {
  height: calc(100vh - 350px);
  overflow: auto;
}

// .spinner-container {
//   position: absolute;
//   top: 0;
//   left: 0;
//   background: rgba($color: #000000, $alpha: 0.6);
//   bottom: 0;
//   right: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex: 1;
// }

.grid-right-pane {
  background-image: url("../../assets/images/mesh.png");
  background-size: 100% auto;
}

.lg-container {
  margin: 5px auto;
  // height: 100vh;
  max-width: 1360px;
  width: 100%;
}

.sm-container {
  margin: 10px;
  // height: 100vh;
}

// .scrollbar::-webkit-scrollbar {
//   display: none;
// }
/* src/DropdownSearch.css */
.dropdown-container {
  position: relative;

}

.search-input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.loading-text {
  margin-top: 10px;
  font-size: 14px;
  color: #888;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-top: none;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    background-color: #F7F7F7;
    border-radius: 8px;
    margin: 0px 65px;
    width: 75%;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.selected-text {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

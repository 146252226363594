.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.upload-box{
  border-style: dashed;
  border-width: 2px;
  width: 100%;
  border-color: #ccc;
  border-radius: 10px;
  background-color: #fff;
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.upload-button{
  text-align: center;
  display: flex !important;
  margin-top: 20px !important;
  width: 100%;
  box-shadow: 0px;
  color: white !important;
  border-radius: 5px !important;
  // text-transform: capitalize !important;
  font-weight: 600 !important;
  background-color: #EE851E !important;
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  z-index: 999 !important;
  height: 110vh;
}

main-container{
  height: calc(100vh - 150px);
  overflow: auto;
}

/* Define scrollbar styles */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

/* Track styles */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Handle styles */
::-webkit-scrollbar-thumb {
  background: #daa466; /* Color of the scrollbar handle */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}


.text-decorat {
  background: linear-gradient(to right, #EE851E 20%, #d7db70 30%, #0190cd 70%, #e97a16 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 500% auto;
  animation: textShine 5s ease-in-out infinite alternate;
}

@keyframes textShine {
  0% {
      background-position: 0% 50%;
  }
  100% {
      background-position: 100% 50%;
  }
}


.item_text{
  animation: key1 .7s ease infinite alternate;
}

@keyframes key1{
  0% {transform: translateY(0px);}
  100% {transform: translateY(-8px);}
}